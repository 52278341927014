<template>
    <TransitionRoot as="template" :show="open">
        <Dialog
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto"
            @close="
                open = false;
                $emit('onClose');
            "
        >
            <div class="flex items-end justify-center text-center">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay
                        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                    >&#8203;</span
                >
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="fixed top-0 sm:top-8 inline-block sm:rounded-sm h-screen sm:h-7/8 align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-4xl sm:w-full"
                    >
                        <!-- <div class="flex-none"> -->
                        <div
                            class="cursor-pointer w-fit"
                            @click="
                                open = false;
                                $emit('onClose');
                            "
                        >
                            <Icon
                                name="XIcon"
                                size="6"
                                color="gray-600"
                                customClass="absolute right-4 top-4"
                            />
                        </div>
                        <div class="flex mt-12 mb-4 px-4 sm:px-12">
                            <h1 class="page-title">
                                <strong
                                    >TERMS AND CONDITIONS FOR USE OF
                                    WEBSITE</strong
                                >
                            </h1>
                        </div>
                        <!-- </div> -->

                        <article
                            id="de1d6fcd-30f7-4dd5-bb6c-568b21bd0fa8"
                            class="page sans px-4 sm:px-12 h-full overflow-y-scroll"
                        >
                            <div class="page-body space-y-3 pb-80">
                                <p
                                    id="953062b9-84fd-4f72-9012-731403e09965"
                                    class=""
                                ></p>
                                <p
                                    id="a105dc11-202d-401c-8a1e-5c62fa5742b5"
                                    class=""
                                >
                                    Welcome to the DSCVRY website (including all
                                    subdomains, the “Site”), which is operated
                                    by Start with BLDR LLC (collectively,
                                    “BLDR,” “we”, “us” and/or “our”). This Site
                                    provides details on our process, our
                                    history, and our approach to helping you
                                    cultivate your business idea and collects
                                    information from you to assist us in
                                    providing services to you.
                                </p>
                                <p
                                    id="7471cc0e-a556-4bfb-9c36-af489b7ac37f"
                                    class=""
                                >
                                    THESE TERMS OF USE CONTAIN AN ARBITRATION
                                    AGREEMENT, WHICH WILL, WITH LIMITED
                                    EXCEPTION, REQUIRE YOU TO SUBMIT CLAIMS YOU
                                    HAVE AGAINST US TO BINDING AND FINAL
                                    ARBITRATION. UNDER THE ARBITRATION
                                    AGREEMENT, (1) YOU WILL ONLY BE PERMITTED TO
                                    PURSUE CLAIMS AGAINST BLDR ON AN INDIVIDUAL
                                    BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN
                                    ANY CLASS OR REPRESENTATIVE ACTION OR
                                    PROCEEDING, AND (2) YOU WILL ONLY BE
                                    PERMITTED TO SEEK RELIEF (INCLUDING
                                    MONETARY, INJUNCTIVE, AND DECLARATORY
                                    RELIEF) ON AN INDIVIDUAL BASIS
                                </p>
                                <p
                                    id="dab34766-f246-4f53-af2c-db01574b613b"
                                    class=""
                                >
                                    We reserve the right, at our sole
                                    discretion, to change or modify portions of
                                    these Terms of Use at any time. In the case
                                    of a change to these Terms,  we will post
                                    the changes on this page and will indicate
                                    at the top of this page the date these terms
                                    were last revised. Your continued use of the
                                    Site after the date any such changes become
                                    effective constitutes your acceptance of the
                                    new Terms of Use.
                                </p>
                                <p
                                    id="7dc12976-2c32-48f9-86b5-5cf75ea436cc"
                                    class=""
                                >
                                    In addition, when using certain components
                                    of the Site, you will be subject to any
                                    additional terms, policies, rules or
                                    guidelines applicable to the Site or such
                                    components of the Site that may be posted on
                                    the Site from time to time, including,
                                    without limitation, the Privacy Policy
                                    located at
                                    <a href="https://www.dscvry.co/privacy"
                                        >https://www.dscvry.co/privacy</a
                                    >
                                    . All such terms are hereby incorporated by
                                    reference into these Terms of Use.
                                </p>
                                <p
                                    id="65f3e983-3f0c-4185-adec-b1b3f17d89f5"
                                    class=""
                                >
                                    <strong>Access and Use of the Site</strong>
                                </p>
                                <p
                                    id="6350ba81-30f1-4f30-a201-94aa4022c7b6"
                                    class=""
                                >
                                    <em>Registration</em>: You will be required
                                    to register with BLDR in order to access and
                                    use certain features of the Site. In
                                    registering, you agree to provide and
                                    maintain true, accurate, current and
                                    complete information about yourself as
                                    prompted by the Site. Registration data and
                                    certain other information about you are
                                    governed by our Privacy Policy. If you are
                                    under 13 years of age, you are not
                                    authorized to register to use the Site.
                                </p>
                                <p
                                    id="6fca8d53-4309-4c8f-b8d1-bc43be7643b4"
                                    class=""
                                >
                                    <em
                                        >Member Account, Password and
                                        Security</em
                                    >: You are responsible for maintaining the
                                    confidentiality of your password and
                                    account, if any, and are fully responsible
                                    for any and all activities that occur under
                                    your account. You agree to (a) immediately
                                    notify BLDR of any unauthorized use of your
                                    password or account or any other breach of
                                    security, and (b) ensure that you exit from
                                    your account at the end of each session when
                                    accessing the Site. BLDR will not be liable
                                    for any loss or damage arising from your
                                    failure to comply with this Section.
                                </p>
                                <p
                                    id="668e7a03-b4fd-4ba8-9a37-661bdfb1e54c"
                                    class=""
                                >
                                    <em>Modifications to Site</em>: BLDR
                                    reserves the right to modify or discontinue,
                                    temporarily or permanently, the Site (or any
                                    part thereof) with or without notice. You
                                    agree that BLDR will not be liable to you or
                                    to any third party for any modification,
                                    suspension or discontinuance of the Site.
                                </p>
                                <p
                                    id="3a6e7f93-df4a-4f73-80db-7a23242161ba"
                                    class=""
                                >
                                    <em>Use and Storage</em>: You acknowledge
                                    that BLDR may establish general policies,
                                    procedures, and limits concerning use of the
                                    Site, including without limitation the
                                    maximum period of time that data or other
                                    content will be retained by the Site and the
                                    maximum storage space that will be allotted
                                    on BLDR’s servers on your behalf. You agree
                                    that BLDR has no responsibility or liability
                                    for the deletion or failure to store any
                                    data or other content maintained or uploaded
                                    by the Site. You acknowledge that BLDR
                                    reserves the right to terminate accounts
                                    that are inactive for an extended period of
                                    time. You further acknowledge that BLDR
                                    reserves the right to change these general
                                    policies, procedures, and limits at any
                                    time, in its sole discretion, with or
                                    without notice.
                                </p>
                                <p
                                    id="b719d1a6-8986-4b58-9867-4ac7ddda2922"
                                    class=""
                                >
                                    <strong>Conditions Of Your Use</strong>
                                </p>
                                <p
                                    id="aef681b5-c8dd-4f9f-875a-8e35d77257e5"
                                    class=""
                                >
                                    <em>User Conduct</em>: In addition to any
                                    applicable guidelines or policies that may
                                    govern specific components of the Site, you
                                    agree to comply with the following
                                    conditions in using the Site. You are solely
                                    responsible for all code, video, images,
                                    information, data, text, software, music,
                                    sound, photographs, graphics, messages or
                                    other materials (“content”) that you upload,
                                    post, publish or display (hereinafter,
                                    “upload”) or email or otherwise transmit via
                                    the Site. The following are examples of the
                                    kind of content and/or use that is illegal
                                    or prohibited by BLDR. BLDR reserves the
                                    right to investigate and take appropriate
                                    legal action against anyone who, in BLDR’s
                                    sole discretion, violates this provision,
                                    including without limitation, removing the
                                    offending content from the Site, suspending
                                    or terminating the account of such violators
                                    and reporting you to the law enforcement
                                    authorities. You agree to not use the Site
                                    to:
                                </p>
                                <p
                                    id="ba2b57b3-ae8c-4f30-a61e-8dda5e30ebd3"
                                    class=""
                                >
                                    a) email or otherwise upload any content
                                    that
                                </p>
                                <ol
                                    type="1"
                                    id="b89352e8-cb0c-4c21-a720-eba1f40d3198"
                                    class="numbered-list"
                                    start="1"
                                >
                                    <li>
                                        infringes any intellectual property or
                                        other proprietary rights of any party;
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="e8f0c435-752a-4d34-9467-7c66d967626a"
                                    class="numbered-list"
                                    start="2"
                                >
                                    <li>
                                        you do not have a right to upload under
                                        any law or under contractual or
                                        fiduciary relationships; (iii)
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="bcb68664-abe3-4427-bd0a-0befa8a72ca7"
                                    class="numbered-list"
                                    start="3"
                                >
                                    <li>
                                        contains software viruses or any other
                                        computer code, files or programs
                                        designed to interrupt, destroy or limit
                                        the functionality of any computer
                                        software or hardware or
                                        telecommunications equipment; (iv)
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="9cc09b18-8ec5-4a6c-a5bc-f03cd289623b"
                                    class="numbered-list"
                                    start="4"
                                >
                                    <li>
                                        poses or creates a privacy or security
                                        risk to any person; (v)
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="b65333bd-7cd3-4955-8f71-d43cab0cb549"
                                    class="numbered-list"
                                    start="5"
                                >
                                    <li>
                                        constitutes unsolicited or unauthorized
                                        advertising, promotional materials,
                                        commercial activities and/or sales,
                                        “junk mail,” “spam,” “chain letters,”
                                        “pyramid schemes,” “contests,”
                                        “sweepstakes,” or any other form of
                                        solicitation; (vi)
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="fbda5610-8871-48c3-89da-d04688c3995d"
                                    class="numbered-list"
                                    start="6"
                                >
                                    <li>
                                        is unlawful, harmful, threatening,
                                        abusive, harassing, tortious,
                                        excessively violent, defamatory, vulgar,
                                        obscene, pornographic, libelous,
                                        invasive of another’s privacy, hateful
                                        racially, ethnically or otherwise
                                        objectionable; or (vii)
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="13d6412f-cc9a-4b6b-9cae-1d265d2903b7"
                                    class="numbered-list"
                                    start="7"
                                >
                                    <li>
                                        in the sole judgment of BLDR, is
                                        objectionable or which restricts or
                                        inhibits any other person from using or
                                        enjoying the Site, or which may expose
                                        BLDR or its users to any harm or
                                        liability of any type;
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="1ef8f67e-84df-4749-a419-3e67db0ce190"
                                    class="numbered-list"
                                    start="8"
                                >
                                    <li>
                                        interfere with or disrupt the Site or
                                        servers or networks connected to the
                                        Site, or disobey any requirements,
                                        procedures, policies or regulations of
                                        networks connected to the Site;
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="f4a29ec5-96c7-4519-8479-d6b8b5077398"
                                    class="numbered-list"
                                    start="9"
                                >
                                    <li>
                                        violate any applicable local, state,
                                        national or international law, or any
                                        regulations having the force of law;
                                        impersonate any person or entity, or
                                        falsely state or otherwise misrepresent
                                        your affiliation with a person or
                                        entity;
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="86bb6fd9-4ce7-43c7-8472-1967f9831d27"
                                    class="numbered-list"
                                    start="10"
                                >
                                    <li>
                                        solicit personal information from anyone
                                        under the age of 18;
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="22b7b996-4fba-47ff-8f2e-af4ced46808d"
                                    class="numbered-list"
                                    start="11"
                                >
                                    <li>
                                        harvest or collect email addresses or
                                        other contact information of other users
                                        from the Site by electronic or other
                                        means for the purposes of sending
                                        unsolicited emails or other unsolicited
                                        communications;
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="4a3c7388-a6a2-4f9b-844b-0bc287c43b33"
                                    class="numbered-list"
                                    start="12"
                                >
                                    <li>
                                        advertise or offer to sell or buy any
                                        goods or services for any business
                                        purpose that is not specifically
                                        authorized;
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="8030ac26-5bac-4e3b-a188-8cce8e3a1ed8"
                                    class="numbered-list"
                                    start="13"
                                >
                                    <li>
                                        further or promote any criminal activity
                                        or enterprise or provide instructional
                                        information about illegal activities; or
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="5919a8ab-b1e6-4c02-a884-5bea851b04a5"
                                    class="numbered-list"
                                    start="14"
                                >
                                    <li>
                                        obtain or attempt to access or otherwise
                                        obtain any materials or information
                                        through any means not intentionally made
                                        available or provided for through the
                                        Site.
                                    </li>
                                </ol>
                                <p
                                    id="739b09a4-6456-4e6b-97e8-b716a4fa0b4f"
                                    class=""
                                >
                                    <em>Commercial Use</em>: Unless otherwise
                                    expressly authorized herein or in the Site,
                                    you agree not to display, distribute,
                                    license, perform, publish, reproduce,
                                    duplicate, copy, create derivative works
                                    from, modify, sell, resell, exploit,
                                    transfer or upload for any commercial
                                    purposes, any portion of the Site, use of
                                    the Site, or access to the Site. The buying,
                                    exchanging, selling and/or promotion
                                    (commercial or otherwise) of upvotes,
                                    comments, submissions, accounts (or any
                                    aspect of your account or any other
                                    account), karma, and/or content is strictly
                                    prohibited, constitutes a material breach of
                                    these Terms of Use, and could result in
                                    legal liability.
                                </p>
                                <p
                                    id="83f115cb-8a1c-4ebc-8704-9d2b97e387e4"
                                    class=""
                                >
                                    <strong
                                        >Intellectual Property Rights</strong
                                    >
                                </p>
                                <p
                                    id="ede264e3-1d43-4bff-b387-295df2f6e31c"
                                    class=""
                                >
                                    <em
                                        >Site Content, Software and
                                        Trademarks:</em
                                    >
                                    You acknowledge and agree that the Site may
                                    contain content or features (“Site Content”)
                                    that are protected by copyright, patent,
                                    trademark, trade secret or other proprietary
                                    rights and laws. All Site Content is owned
                                    exclusively by BLDR.  Except as expressly
                                    authorized by BLDR, you agree not to modify,
                                    copy, frame, scrape, rent, lease, loan,
                                    sell, distribute or create derivative works
                                    based on the Site or the Site Content, in
                                    whole or in part, except that the foregoing
                                    does not apply to your own User Content (as
                                    defined below) that you legally upload to
                                    the Site. In connection with your use of the
                                    Site you will not engage in or use any data
                                    mining, robots, scraping or similar data
                                    gathering or extraction methods. If you are
                                    blocked by BLDR from accessing the Site
                                    (including by blocking your IP address), you
                                    agree not to implement any measures to
                                    circumvent such blocking (e.g., by masking
                                    your IP address or using a proxy IP
                                    address). Any use of the Site or the Site
                                    Content other than as specifically
                                    authorized herein is strictly prohibited.
                                    Any rights not expressly granted herein are
                                    reserved by BLDR. BLDR hereby grants User a
                                    nonexclusive, worldwide, royalty free, fully
                                    paid up, transferable, sub-licensable,
                                    perpetual, irrevocable license to copy,
                                    display, upload, perform, distribute, store,
                                    modify and otherwise use Site Content while
                                    User maintains an account on the Site.
                                </p>
                                <p
                                    id="75be2b88-4833-4147-a09c-d4e3640f562e"
                                    class=""
                                >
                                    BLDR Trademarks used and displayed via the
                                    Site may be trademarks of their respective
                                    owners who may or may not endorse or be
                                    affiliated with or connected to BLDR.
                                    Nothing in this Terms of Use or the Site
                                    should be construed as granting, by
                                    implication, estoppel, or otherwise, any
                                    license or right to use any trademarks BLDR
                                    displayed on the Site, without our prior
                                    written permission in each instance. All
                                    goodwill generated from the use of BLDR
                                    Trademarks will inure to our exclusive
                                    benefit.
                                </p>
                                <p
                                    id="976bf380-b6d3-48af-99f1-28f88415d154"
                                    class=""
                                >
                                    <em>Third Party Material</em>: Under no
                                    circumstances will BLDR be liable in any way
                                    for any content or materials of any third
                                    parties (including other users), including,
                                    but not limited to, for any errors or
                                    omissions in any content, or for any loss or
                                    damage of any kind incurred as a result of
                                    the use of any such content. You acknowledge
                                    that BLDR does not pre-screen content, but
                                    that BLDR and its designees will have the
                                    right (but not the obligation) in their sole
                                    discretion to refuse or remove any content
                                    that is available via the Site. Without
                                    limiting the foregoing, BLDR and its
                                    designees will have the right to remove any
                                    content that violates these Terms of Use or
                                    is deemed by BLDR, in its sole discretion,
                                    to be otherwise objectionable. You agree
                                    that you must evaluate, and bear all risks
                                    associated with, the use of any content,
                                    including any reliance on the accuracy,
                                    completeness, or usefulness of such content.
                                </p>
                                <p
                                    id="2ad94934-bd9c-4005-b1a4-538724a9b3a2"
                                    class=""
                                >
                                    <em>User Content Transmitted</em>
                                    <em>Through the Site</em>: With respect to
                                    the content or other materials you upload
                                    through the Site or share with other users
                                    or recipients, including specifically any
                                    proposed business or investment idea,
                                    (collectively, “User Content”), you
                                    represent and warrant that you own all
                                    right, title and interest in and to such
                                    User Content, including, without limitation,
                                    all copyrights and rights of publicity
                                    contained therein. By uploading any User
                                    Content you hereby grant and will grant BLDR
                                    and its affiliated companies a nonexclusive,
                                    worldwide, royalty free, fully paid up,
                                    transferable, sub-licensable, perpetual,
                                    irrevocable license to copy, display,
                                    upload, perform, store, modify and otherwise
                                    use your User Content for any BLDR-related
                                    purpose in any form, medium or technology
                                    now known or later developed. However,
                                    please review the Privacy Policy located
                                    here for more information on how we treat
                                    information submitted to us.
                                </p>
                                <p
                                    id="98c33470-be2d-4db3-affa-41248d16f258"
                                    class=""
                                >
                                    You acknowledge and agree that any
                                    questions, comments, suggestions, ideas,
                                    feedback or other information about the Site
                                    (“Submissions”) provided by you to BLDR are
                                    non-confidential and BLDR will be entitled
                                    to the unrestricted use and dissemination of
                                    these Submissions for any purpose, without
                                    acknowledgment or compensation to you.
                                </p>
                                <p
                                    id="f4596a27-5ba8-4fba-a42f-b1f93bc75b8d"
                                    class=""
                                >
                                    Without limiting the foregoing, you
                                    acknowledge and agree that BLDR may preserve
                                    content and may also disclose content if
                                    required to do so by law or in the good
                                    faith belief that such preservation or
                                    disclosure is reasonably necessary to: (a)
                                    comply with legal process, applicable laws
                                    or government requests; (b) enforce these
                                    Terms of Use; (c) respond to claims that any
                                    content violates the rights of third
                                    parties; or (d) protect the rights,
                                    property, or personal safety of BLDR, its
                                    users and the public. You understand that
                                    the technical processing and transmission of
                                    the Site, including your content, may
                                    involve (a) transmissions over various
                                    networks; and (b) changes to conform and
                                    adapt to technical requirements of
                                    connecting networks or devices.
                                </p>
                                <p
                                    id="9418b212-e9c7-46d9-b340-f0b37b8c7cec"
                                    class=""
                                >
                                    <em>Copyright Complaints</em>: BLDR respects
                                    the intellectual property of others, and we
                                    ask our users to do the same. If you believe
                                    that your work has been copied in a way that
                                    constitutes copyright infringement, or that
                                    your intellectual property rights have been
                                    otherwise violated, you should notify BLDR
                                    of your infringement claim in accordance
                                    with the procedure set forth below.
                                </p>
                                <p
                                    id="1fb3d83d-5d0b-44f5-b9e2-f19a53a9363c"
                                    class=""
                                >
                                    BLDR will process and investigate notices of
                                    alleged infringement and will take
                                    appropriate actions under the Digital
                                    Millennium Copyright Act (“DMCA”) and other
                                    applicable intellectual property laws with
                                    respect to any alleged or actual
                                    infringement. A written notification of
                                    claimed copyright infringement should be
                                    faxed or mailed to:
                                </p>
                                <p
                                    id="bc3dfdda-55a6-4f6f-bd65-4d24a05fc3a2"
                                    class=""
                                >
                                    BLDR<br />1717 E Cary St<br />Richmond, VA
                                    23223<br />
                                </p>
                                <p
                                    id="50b97485-6cec-43bb-aa8b-cc027016ee83"
                                    class=""
                                >
                                    To be effective, the notification must be in
                                    writing and contain the following
                                    information:
                                </p>
                                <ol
                                    type="1"
                                    id="59db204c-997b-4cb8-8f77-bdd393df2e64"
                                    class="numbered-list"
                                    start="1"
                                >
                                    <li>
                                        an electronic or physical signature of
                                        the person authorized to act on behalf
                                        of the owner of the copyright or other
                                        intellectual property interest;
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="1ff692d2-c2f3-4e64-bb41-30bb39022126"
                                    class="numbered-list"
                                    start="2"
                                >
                                    <li>
                                        a description of the copyrighted work or
                                        other intellectual property that you
                                        claim has been infringed;
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="51617d45-ea66-4bf3-a16c-11ce089869ae"
                                    class="numbered-list"
                                    start="3"
                                >
                                    <li>
                                        a description of where the material that
                                        you claim is infringing is located on
                                        the Site, with enough detail that we may
                                        find it on the Site;
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="8bcd5cfc-8f56-4057-abc0-0317e4f14c96"
                                    class="numbered-list"
                                    start="4"
                                >
                                    <li>
                                        your address, telephone number, and
                                        email address;
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="fc629784-da9f-4d65-b29a-7e828a40858a"
                                    class="numbered-list"
                                    start="5"
                                >
                                    <li>
                                        a statement by you that you have a good
                                        faith belief that the disputed use is
                                        not authorized by the copyright or
                                        intellectual property owner, its agent,
                                        or the law;
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="084c9e29-98a3-45f3-8ec5-13ad37917311"
                                    class="numbered-list"
                                    start="6"
                                >
                                    <li>
                                        a statement by you, made under penalty
                                        of perjury, that the above information
                                        in your Notice is accurate and that you
                                        are the copyright or intellectual
                                        property owner or authorized to act on
                                        the copyright or intellectual property
                                        owner’s behalf.
                                    </li>
                                </ol>
                                <p
                                    id="34202b00-701d-42ad-b838-bdca96e7b0d0"
                                    class=""
                                >
                                    Counter-Notice: If you believe that your
                                    User Content that was removed (or to which
                                    access was disabled) is not infringing, or
                                    that you have the authorization from the
                                    copyright owner, the copyright owner’s
                                    agent, or pursuant to the law, to upload and
                                    use the content in your User Content, you
                                    may send a written counter-notice containing
                                    the following information to the Copyright
                                    Agent:
                                </p>
                                <ol
                                    type="1"
                                    id="15c4f3af-3427-4ce2-87ae-4c8175282569"
                                    class="numbered-list"
                                    start="1"
                                >
                                    <li>
                                        your physical or electronic signature;
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="3ed12119-10e4-4002-bb06-ad554be75f4d"
                                    class="numbered-list"
                                    start="2"
                                >
                                    <li>
                                        identification of the content that has
                                        been removed or to which access has been
                                        disabled and the location at which the
                                        content appeared before it was removed
                                        or disabled;
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="b220761c-6b92-4492-9e2d-ff2854a4e2ff"
                                    class="numbered-list"
                                    start="3"
                                >
                                    <li>
                                        a statement that you have a good faith
                                        belief that the content was removed or
                                        disabled as a result of mistake or a
                                        misidentification of the content; and
                                    </li>
                                </ol>
                                <ol
                                    type="1"
                                    id="39b75f96-dc3d-42de-80c1-3fb2b7767bac"
                                    class="numbered-list"
                                    start="4"
                                >
                                    <li>
                                        your name, address, telephone number,
                                        and email address, a statement that you
                                        consent to the jurisdiction of the
                                        federal court located within Northern
                                        District of California and a statement
                                        that you will accept service of process
                                        from the person who provided
                                        notification of the alleged
                                        infringement.
                                    </li>
                                </ol>
                                <p
                                    id="ae528f24-30fd-47f8-9e78-1689af215439"
                                    class=""
                                >
                                    If a counter-notice is received by BLDR,
                                    then it will send a copy of the
                                    counter-notice to the original complaining
                                    party informing that person that it may
                                    replace the removed content or cease
                                    disabling it in 10 business days.
                                </p>
                                <p
                                    id="52b79f96-8e55-4845-930a-adbdb1e7b428"
                                    class=""
                                >
                                    <em>Repeat Infringer Policy</em>: In
                                    accordance with the DMCA and other
                                    applicable law, BLDR has adopted a policy of
                                    terminating, in appropriate circumstances
                                    and at BLDR&#x27;s sole discretion, users
                                    who are deemed to be repeat infringers. BLDR
                                    may also at its sole discretion limit access
                                    to the Site and/or terminate the
                                    registrations of any users who infringe any
                                    intellectual property rights of others,
                                    whether or not there is any repeat
                                    infringement.
                                </p>
                                <p
                                    id="0b795fab-4526-4488-8283-ec754f5a1daa"
                                    class=""
                                >
                                    <strong>Third Party Websites</strong>
                                </p>
                                <p
                                    id="61bc0e3d-34ce-41ca-8e9d-cc1d91ee64a9"
                                    class=""
                                >
                                    The Site may provide, or third parties may
                                    provide, links or other access to other
                                    sites and resources on the Internet or to
                                    third party applications. BLDR has no
                                    control over such sites, resources or
                                    applications and BLDR is not responsible for
                                    and does not endorse such sites, resources
                                    or applications. You further acknowledge and
                                    agree that BLDR will not be liable, directly
                                    or indirectly, for any damage or loss caused
                                    or alleged to be caused by or in connection
                                    with use of or reliance on any content,
                                    events, goods or services available on or
                                    through any such sites, resources or
                                    applications. Any dealings you have with
                                    third parties found while using the Site are
                                    between you and the third party, and you
                                    agree that BLDR is not liable for any loss
                                    or claim that you may have against any such
                                    third party.
                                </p>
                                <p
                                    id="99ddfad3-01aa-4242-83f5-077abbe9204a"
                                    class=""
                                >
                                    <strong>Indemnity and Release</strong>
                                </p>
                                <p
                                    id="c4a9a831-13ca-4a88-9639-31709d6ccd11"
                                    class=""
                                >
                                    You agree to release, indemnify and hold
                                    BLDR and its affiliates and their officers,
                                    employees, directors and agents harmless
                                    from any from any and all losses, damages,
                                    expenses, including reasonable attorneys’
                                    fees, rights, claims, actions of any kind
                                    and injury (including death) arising out of
                                    or relating to your use of the Site or any
                                    related information, any User Content, your
                                    application(s) to BLDR or the results
                                    thereof, your violation of these Terms of
                                    Use or your violation of any rights of
                                    another. If you are a California resident,
                                    you waive California Civil Code Section
                                    1542, which says: “A general release does
                                    not extend to claims which the creditor does
                                    not know or suspect to exist in his favor at
                                    the time of executing the release, which if
                                    known by him must have materially affected
                                    his settlement with the debtor.” If you are
                                    a resident of another jurisdiction, you
                                    waive any comparable statute or doctrine.
                                </p>
                                <p
                                    id="bdd430af-62b3-4650-8496-66c7411fb649"
                                    class=""
                                >
                                    <strong>Disclaimer of Warranties</strong>
                                </p>
                                <p
                                    id="0af98530-1bf8-4bba-bee2-8bf66cb6accf"
                                    class=""
                                >
                                    YOUR USE OF THE SITE IS AT YOUR SOLE RISK.
                                    THE SITE IS PROVIDED ON AN “AS IS” AND “AS
                                    AVAILABLE” BASIS. BLDR EXPRESSLY DISCLAIMS
                                    ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS,
                                    IMPLIED OR STATUTORY, INCLUDING, BUT NOT
                                    LIMITED TO THE IMPLIED WARRANTIES OF
                                    MERCHANTABILITY, FITNESS FOR A PARTICULAR
                                    PURPOSE, TITLE AND NON-INFRINGEMENT.
                                </p>
                                <p
                                    id="773049db-6ce9-420c-accc-ff4eb2a9fc86"
                                    class=""
                                >
                                    BLDR MAKES NO WARRANTY THAT (I) THE SITE
                                    WILL MEET YOUR REQUIREMENTS, (II) THE SITE
                                    WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
                                    ERROR-FREE, OR (III) THE RESULTS THAT MAY BE
                                    OBTAINED FROM THE USE OF THE SITE WILL BE
                                    ACCURATE OR RELIABLE.
                                </p>
                                <p
                                    id="f12e8c12-465b-49b7-964e-3070dc5e8468"
                                    class=""
                                >
                                    <strong>Limitation of Liability</strong> YOU
                                    EXPRESSLY UNDERSTAND AND AGREE THAT BLDR
                                    WILL NOT BE LIABLE FOR ANY INDIRECT,
                                    INCIDENTAL, SPECIAL, CONSEQUENTIAL,
                                    EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF
                                    PROFITS INCLUDING BUT NOT LIMITED TO,
                                    DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR
                                    OTHER INTANGIBLE LOSSES (EVEN IF BLDR HAS
                                    BEEN ADVISED OF THE POSSIBILITY OF SUCH
                                    DAMAGES), WHETHER BASED ON CONTRACT, TORT,
                                    NEGLIGENCE, STRICT LIABILITY OR OTHERWISE,
                                    RESULTING FROM: (I) THE USE OR THE INABILITY
                                    TO USE THE SITE OR ANY RELATED INFORMATION;
                                    (II) UNAUTHORIZED ACCESS TO OR ALTERATION OF
                                    YOUR SUBMISSIONS OR DATA; (III) STATEMENTS
                                    OR CONDUCT OF ANY THIRD PARTY (INCLUDING
                                    USERS) ON THE SITE; OR (IV) ANY OTHER MATTER
                                    RELATING TO THE SITE. IN NO EVENT WILL
                                    BLDR’S TOTAL LIABILITY TO YOU FOR ALL
                                    DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED
                                    ONE HUNDRED DOLLARS ($100).
                                </p>
                                <p
                                    id="de89e32a-6308-4b4a-8f84-6432729dc908"
                                    class=""
                                >
                                    SOME JURISDICTIONS DO NOT ALLOW THE
                                    EXCLUSION OF CERTAIN WARRANTIES OR THE
                                    LIMITATION OR EXCLUSION OF LIABILITY FOR
                                    INCIDENTAL OR CONSEQUENTIAL DAMAGES.
                                    ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS
                                    SET FORTH ABOVE MAY NOT APPLY TO YOU. IF YOU
                                    ARE DISSATISFIED WITH ANY PORTION OF THE
                                    SITE OR WITH THESE TERMS OF USE, YOUR SOLE
                                    AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE
                                    OF THE SITE.
                                </p>
                                <p
                                    id="1b153fd0-90ec-4b7c-a1ad-ded4de6feec0"
                                    class=""
                                >
                                    WITHOUT LIMITING THE FOREGOING, IF YOU ARE A
                                    USER FROM NEW JERSEY, THE ‘DISCLAIMER OF
                                    WARRANTIES’ AND ‘LIMITATION OF LIABILITY’
                                    SECTIONS ABOVE ARE INTENDED TO BE ONLY AS
                                    BROAD AS IS PERMITTED UNDER NEW JERSEY LAW.
                                    IF ANY PORTION OF THESE SECTIONS IS HELD TO
                                    BE INVALID UNDER NEW JERSEY LAW, THE
                                    INVALIDITY OF SUCH PORTION SHALL NOT AFFECT
                                    THE VALIDITY OF THE REMAINING PORTIONS OF
                                    THE APPLICABLE SECTION.
                                </p>
                                <p
                                    id="05b2278e-7dcb-4dca-b2bb-95160ab8330e"
                                    class=""
                                >
                                    <strong>Dispute Resolution</strong> By
                                    Binding Arbitration:
                                    <strong
                                        >PLEASE READ THIS SECTION CAREFULLY AS
                                        IT AFFECTS YOUR RIGHTS</strong
                                    >.
                                </p>
                                <p
                                    id="5b80d688-4bc9-4703-8414-0502ff69f6d6"
                                    class=""
                                >
                                    a. <em>Agreement to Arbitrate</em>
                                </p>
                                <p
                                    id="af4aadbf-f75e-4541-9d1a-654be61158a7"
                                    class=""
                                >
                                    This Dispute Resolution by Binding
                                    Arbitration section is referred to in this
                                    Terms of Use as the “Arbitration Agreement.”
                                    You agree that any and all disputes or
                                    claims that have arisen or may arise between
                                    you and BLDR, whether arising out of or
                                    relating to this Terms of Use (including any
                                    alleged breach thereof), the Site, any
                                    advertising, any aspect of the relationship
                                    or transactions between us, shall be
                                    resolved exclusively through final and
                                    binding arbitration, rather than a court, in
                                    accordance with the terms of this
                                    Arbitration Agreement, except that you may
                                    assert individual claims in small claims
                                    court, if your claims qualify. Further, this
                                    Arbitration Agreement does not preclude you
                                    from bringing issues to the attention of
                                    federal, state, or local agencies, and such
                                    agencies can, if the law allows, seek relief
                                    against us on your behalf. You agree that,
                                    by entering into this Terms of Use, you and
                                    BLDR are each waiving the right to a trial
                                    by jury or to participate in a class action.
                                    Your rights will be determined by a neutral
                                    arbitrator, not a judge or jury. The Federal
                                    Arbitration Act governs the interpretation
                                    and enforcement of this Arbitration
                                    Agreement.
                                </p>
                                <p
                                    id="ee423f60-a82a-41c9-bb8c-4292f92ac3c9"
                                    class=""
                                >
                                    b.
                                    <em
                                        >Prohibition of Class and Representative
                                        Actions and Non-Individualized
                                        Relief</em
                                    >
                                </p>
                                <p
                                    id="c6e29a08-acf8-48ec-b973-603700e734c9"
                                    class=""
                                >
                                    YOU AND BLDR AGREE THAT EACH OF US MAY BRING
                                    CLAIMS AGAINST THE OTHER ONLY ON AN
                                    INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR
                                    CLASS MEMBER IN ANY PURPORTED CLASS OR
                                    REPRESENTATIVE ACTION OR PROCEEDING. UNLESS
                                    BOTH YOU AND BLDR AGREE OTHERWISE, THE
                                    ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE
                                    THAN ONE PERSON’S OR PARTY’S CLAIMS AND MAY
                                    NOT OTHERWISE PRESIDE OVER ANY FORM OF A
                                    CONSOLIDATED, REPRESENTATIVE, OR CLASS
                                    PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD
                                    RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
                                    DECLARATORY RELIEF) ONLY IN FAVOR OF THE
                                    INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO
                                    THE EXTENT NECESSARY TO PROVIDE RELIEF
                                    NECESSITATED BY THAT PARTY’S INDIVIDUAL
                                    CLAIM(S).
                                </p>
                                <p
                                    id="038bb62a-c44a-4c33-a868-d2b550211cbd"
                                    class=""
                                >
                                    c.
                                    <em>Pre-Arbitration Dispute Resolution</em>
                                </p>
                                <p
                                    id="850f5ee3-bb5d-446a-a4bc-3112cc29d3a1"
                                    class=""
                                >
                                    BLDR is always interested in resolving
                                    disputes amicably and efficiently, and most
                                    user concerns can be resolved quickly and to
                                    the user’s satisfaction by emailing us at
                                    info@dscvry.co. If such efforts prove
                                    unsuccessful, a party who intends to seek
                                    arbitration must first send to the other, by
                                    certified mail, a written Notice of Dispute
                                    (“Notice”). The Notice to BLDR should be
                                    sent to BLDR 1717 E Cary St. Richmond, VA
                                    23223 Attention CEO (“Notice Address”). The
                                    Notice must (i) describe the nature and
                                    basis of the claim or dispute and (ii) set
                                    forth the specific relief sought. If BLDR
                                    and you do not resolve the claim within
                                    sixty (60) calendar days after the Notice is
                                    received, you or BLDR may commence an
                                    arbitration proceeding. During the
                                    arbitration, the amount of any settlement
                                    offer made by BLDR or you shall not be
                                    disclosed to the arbitrator until after the
                                    arbitrator determines the amount, if any, to
                                    which you or BLDR is entitled.
                                </p>
                                <p
                                    id="eb74d223-d232-477c-b36e-ff6331f379a6"
                                    class=""
                                >
                                    d. <em>Arbitration Procedures</em>
                                </p>
                                <p
                                    id="0d2f7b94-fdce-43e9-afc9-9b2afa63f775"
                                    class=""
                                >
                                    Arbitration will be conducted by a neutral
                                    arbitrator in accordance with the American
                                    Arbitration Association’s (“AAA”) rules and
                                    procedures (collectively, the “AAA Rules”),
                                    as modified by this Arbitration Agreement.
                                    For information on the AAA, please visit its
                                    website, http://www.adr.org. If there is any
                                    inconsistency between any term of the AAA
                                    Rules and any term of this Arbitration
                                    Agreement, the applicable terms of this
                                    Arbitration Agreement will control unless
                                    the arbitrator determines that the
                                    application of the inconsistent Arbitration
                                    Agreement terms would not result in a
                                    fundamentally fair arbitration. The
                                    arbitrator must also follow the provisions
                                    of these Terms of Use as a court would. All
                                    issues are for the arbitrator to decide,
                                    including, but not limited to, issues
                                    relating to the scope, enforceability, and
                                    arbitrability of this Arbitration Agreement.
                                    Although arbitration proceedings are usually
                                    simpler and more streamlined than trials and
                                    other judicial proceedings, the arbitrator
                                    can award the same damages and relief on an
                                    individual basis that a court can award to
                                    an individual under the Terms of Use and
                                    applicable law. Decisions by the arbitrator
                                    are enforceable in court and may be
                                    overturned by a court only for very limited
                                    reasons.
                                </p>
                                <p
                                    id="ca37f59b-642f-4fbc-9e79-fe98a6dcbae0"
                                    class=""
                                >
                                    Unless BLDR and you agree otherwise, any
                                    arbitration hearings will take place in a
                                    reasonably convenient location for both
                                    parties with due consideration of their
                                    ability to travel and other pertinent
                                    circumstances. If the parties are unable to
                                    agree on a location, the determination shall
                                    be made by AAA. If your claim is for $10,000
                                    or less, BLDR agrees that you may choose
                                    whether the arbitration will be conducted
                                    solely on the basis of documents submitted
                                    to the arbitrator, through a telephonic
                                    hearing, or by an in-person hearing as
                                    established by the AAA Rules. If your claim
                                    exceeds $10,000, the right to a hearing will
                                    be determined by the AAA Rules. Regardless
                                    of the manner in which the arbitration is
                                    conducted, the arbitrator shall issue a
                                    reasoned written decision sufficient to
                                    explain the essential findings and
                                    conclusions on which the award is based.
                                </p>
                                <p
                                    id="5d51cdb5-6149-417e-9880-c8d0b51fa492"
                                    class=""
                                >
                                    e. <em>Costs of Arbitration</em>
                                </p>
                                <p
                                    id="6f670516-e0f1-47a3-8277-55ea81bb67a8"
                                    class=""
                                >
                                    Payment of all filing, administration, and
                                    arbitrator fees (collectively, the
                                    “Arbitration Fees”) will be governed by the
                                    AAA Rules, unless otherwise provided in this
                                    Arbitration Agreement.
                                </p>
                                <p
                                    id="41df47b7-8b40-4534-92fe-ba618916d4f2"
                                    class=""
                                >
                                    f. <em>Severability</em>
                                </p>
                                <p
                                    id="ae747f50-c6b6-4081-9ff4-3d446cbda8d0"
                                    class=""
                                >
                                    If a court or the arbitrator decides that
                                    any term or provision of this Arbitration
                                    Agreement (other than the subsection (b)
                                    titled “Prohibition of Class and
                                    Representative Actions and
                                    Non-Individualized Relief” above) is invalid
                                    or unenforceable, the parties agree to
                                    replace such term or provision with a term
                                    or provision that is valid and enforceable
                                    and that comes closest to expressing the
                                    intention of the invalid or unenforceable
                                    term or provision, and this Arbitration
                                    Agreement shall be enforceable as so
                                    modified. If a court or the arbitrator
                                    decides that any of the provisions of
                                    subsection (b) above titled “Prohibition of
                                    Class and Representative Actions and
                                    Non-Individualized Relief” are invalid or
                                    unenforceable, then the entirety of this
                                    Arbitration Agreement shall be null and
                                    void. The remainder of the Terms of Use will
                                    continue to apply.
                                </p>
                                <p
                                    id="b69804b4-df85-4a09-a8f7-8f2529730d58"
                                    class=""
                                >
                                    <em>g. Confidentiality</em>
                                </p>
                                <p
                                    id="0f2704e9-4b1d-4bba-a86e-8cdb1024a3f0"
                                    class=""
                                >
                                    All aspects of the arbitration proceeding,
                                    and any ruling, decision, or award by the
                                    arbitrator, will be held strictly
                                    confidential for the benefit of all parties.
                                </p>
                                <p
                                    id="dd2e8ab8-de36-4420-a6d8-d4c839142b3a"
                                    class=""
                                >
                                    h.
                                    <em
                                        >Future Changes to Arbitration
                                        Agreement</em
                                    >
                                </p>
                                <p
                                    id="9fe2e97b-da34-4c67-a9bf-259fb95c7291"
                                    class=""
                                >
                                    Notwithstanding any provision in this Terms
                                    of Use to the contrary, BLDR agrees that if
                                    it makes any future change to this
                                    Arbitration Agreement (other than a change
                                    to the Notice Address) while you are a user
                                    of the Site, you may reject any such change
                                    by sending BLDR written notice within thirty
                                    (30) calendar days of the change to the
                                    Notice Address provided above. By rejecting
                                    any future change, you are agreeing that you
                                    will arbitrate any dispute between us in
                                    accordance with the language of this
                                    Arbitration Agreement as of the date you
                                    first accepted these Terms of Use (or
                                    accepted any subsequent changes to these
                                    Terms of Use).
                                </p>
                                <p
                                    id="a8b08789-b375-4b57-bb3e-df2e2eac0550"
                                    class=""
                                >
                                    <strong>Termination</strong>
                                </p>
                                <p
                                    id="631ba44d-4ae8-4732-a7e7-b20dd41e826a"
                                    class=""
                                >
                                    You agree that BLDR, in its sole discretion,
                                    may suspend or terminate your account (or
                                    any part thereof) or use of the Site and
                                    remove and discard any content within the
                                    Site, for any reason, including, without
                                    limitation, for lack of use or if BLDR
                                    believes that you have violated or acted
                                    inconsistently with the letter or spirit of
                                    these Terms of Use. Any suspected
                                    fraudulent, abusive or illegal activity that
                                    may be grounds for termination of your use
                                    of Site, may be referred to appropriate law
                                    enforcement authorities. BLDR may also in
                                    its sole discretion and at any time
                                    discontinue providing the Site, or any part
                                    thereof, with or without notice. You agree
                                    that any termination of your access to the
                                    Site under this Terms of Use may be made
                                    without prior notice, and acknowledge and
                                    agree that BLDR may (but has no obligation
                                    to) immediately deactivate or delete your
                                    account and all related information and
                                    files in your account and bar any further
                                    access to such files or the Site.
                                    Termination of your account or access to any
                                    component of the Site will not terminate
                                    BLDR’s rights to your User Content. Further,
                                    you agree that BLDR will not be liable to
                                    you or any third party for any termination
                                    of your access to the Site.
                                </p>
                                <p
                                    id="2bd43a7f-7e47-4f36-b603-d9cec94d995f"
                                    class=""
                                >
                                    <strong>User Disputes</strong>
                                </p>
                                <p
                                    id="6190f292-8582-4bc3-a35c-da70c84af6f3"
                                    class=""
                                >
                                    You agree that you are solely responsible
                                    for your interactions with any other user in
                                    connection with the Site and BLDR will have
                                    no liability or responsibility with respect
                                    thereto. BLDR reserves the right, but has no
                                    obligation, to become involved in any way
                                    with disputes between you and any other user
                                    of the Site.
                                </p>
                                <p
                                    id="e944ffb6-12f7-447d-a724-cb29af1d0b71"
                                    class=""
                                >
                                    <strong>General</strong>
                                </p>
                                <p
                                    id="67db70c2-8145-41a6-a5b8-074115c37b8a"
                                    class=""
                                >
                                    These Terms of Use constitute the entire
                                    agreement between you and BLDR and govern
                                    your use of the Site, superseding any prior
                                    agreements between you and BLDR with respect
                                    to the Site. These Terms of Use will be
                                    governed by the laws of the Commonwealth of
                                    Virginia without regard to its conflict of
                                    law provisions. With respect to any disputes
                                    or claims not subject to arbitration, as set
                                    forth above, you and BLDR agree to submit to
                                    the personal and exclusive jurisdiction of
                                    the state and federal courts located within
                                    Richmond, Virginia. The failure of BLDR to
                                    exercise or enforce any right or provision
                                    of these Terms of Use will not constitute a
                                    waiver of such right or provision. If any
                                    provision of these Terms of Use is found by
                                    a court of competent jurisdiction to be
                                    invalid, the parties nevertheless agree that
                                    the court should endeavor to give effect to
                                    the parties’ intentions as reflected in the
                                    provision, and the other provisions of these
                                    Terms of Use remain in full force and
                                    effect. You agree that regardless of any
                                    statute or law to the contrary, any claim or
                                    cause of action arising out of or related to
                                    use of the Site or these Terms of Use must
                                    be filed within one (1) year after such
                                    claim or cause of action arose or be forever
                                    barred. A printed version of this agreement
                                    and of any notice given in electronic form
                                    will be admissible in judicial or
                                    administrative proceedings based upon or
                                    relating to this agreement to the same
                                    extent and subject to the same conditions as
                                    other business documents and records
                                    originally generated and maintained in
                                    printed form. You may not assign this Terms
                                    of Use without the prior written consent of
                                    BLDR, but BLDR may assign or transfer this
                                    Terms of Use, in whole or in part, without
                                    restriction. The section titles in these
                                    Terms of Use are for convenience only and
                                    have no legal or contractual effect. Notices
                                    to you may be made via either email or
                                    regular mail. The Site may also provide
                                    notices to you of changes to these Terms of
                                    Use or other matters by displaying notices
                                    or links to notices generally on the Site.
                                </p>
                                <p
                                    id="825b3bf3-bb50-4522-84f9-e0f082f03f59"
                                    class=""
                                >
                                    <strong>Your Privacy</strong>
                                </p>
                                <p
                                    id="9fa4ee3b-79f8-44a8-b495-35531c309c0f"
                                    class=""
                                >
                                    At BLDR, we respect the privacy of our
                                    users. For details please see our
                                    <a href="https://www.dscvry.co/privacy"
                                        >Privacy Policy</a
                                    >. By using the Site, you consent to our
                                    collection and use of personal data as
                                    outlined therein.
                                </p>
                                <p
                                    id="6af52b03-0d2e-4c30-9c84-00f0b34eac54"
                                    class=""
                                >
                                    <strong>Questions? Concerns</strong>?
                                </p>
                                <p
                                    id="731273bc-262d-4ba7-9352-ca6de338f973"
                                    class=""
                                >
                                    Please contact us at info@dscvry.co to
                                    report any violations of these Terms of Use
                                    or to pose any questions regarding this
                                    Terms of Use or the Site.
                                </p>
                            </div>
                        </article>
                        <!-- <div class="flex-none"> -->
                        <div
                            class="fixed w-full bottom-0 bg-gray-100 border-t h-auto py-4 px-8"
                        >
                            <div class="flex justify-end">
                                <Button
                                    :content="btnText"
                                    variant="primary"
                                    :isNoHoverBorder="true"
                                    :click="() => $emit('onClose')"
                                />
                            </div>
                        </div>
                        <!-- </div> -->
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import Icon from "../../../atoms/Icons/Icons.vue";
import Button from "../../../atoms/Button/Button.vue";
import Text from "../../../atoms/Text/Text.vue";
/**
 * - Use it to show a simple modal.
 */
export default {
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        Icon,
        Button,
        Text,
    },
    props: {
        /**
         * Use it for modal title
         */
        title: {
            type: String,
            default: "",
        },
        /**
         * Use it for modal description
         */
        content: {
            type: Array,
            default: () => [],
        },
        /**
         * Use it for yes button text
         */
        open: {
            type: Boolean,
            default: true,
        },

        /**
         * Use it for no button text
         */
        btnText: {
            type: String,
            default: "",
        },
        /**
         * Use it for no buttona action
         */
        onBtnClick: {
            type: Function,
            default: () => {},
        },
    },
};
</script>
