<template>
  <div class="grid w-full min-h-screen grid-cols-1 md:grid-cols-5">
    <div class="flex flex-col h-screen overflow-y-auto col-span-1 md:col-span-2">
      <div class="flex-1 pt-10 sm:pt-20 px-3 sm:px-0 ">
        <div class="max-w-md px-2 mx-auto">
          <img src="../../../../assets/images/dscvry_logo-purple.png" class="h-6" />
          <h2 class="mt-8 text-gray-800">Let's create your account!</h2>
        </div>
        <div class="flex flex-col mt-4">
          <div class="max-w-md px-2 mx-auto space-y-6">
            <Text content="All you need to start is an email address and password" color="gray-800" />
            <div class="space-y-4 rounded-md">
              <Input :isError="inputs.email.error" :help-text="inputs.email.error" id="email"
                v-model:value="inputs.email.value" :rounded="inputs.email.rounded" :type="inputs.email.type"
                :customClass="inputs.email.customClass" :label="inputs.email.label"
                :placeholder="inputs.email.placeholder" />
              <PasswordInput :isError="inputs.password.error" :help-text="inputs.password.error" id="password"
                v-model:value="inputs.password.value" :rounded="inputs.password.rounded" :type="inputs.password.type"
                :customClass="inputs.password.customClass" :label="inputs.password.label"
                :placeholder="inputs.password.placeholder" />
              <Input :isError="inputs.firstName.error" :help-text="inputs.firstName.error" id="firstName"
                v-model:value="inputs.firstName.value" :rounded="inputs.firstName.rounded" :type="inputs.firstName.type"
                :customClass="inputs.firstName.customClass" :label="inputs.firstName.label"
                :placeholder="inputs.firstName.placeholder" />
              <Input :isError="inputs.lastName.error" :help-text="inputs.lastName.error" id="lastName"
                v-model:value="inputs.lastName.value" :rounded="inputs.lastName.rounded" :type="inputs.lastName.type"
                :customClass="inputs.lastName.customClass" :label="inputs.lastName.label"
                :placeholder="inputs.lastName.placeholder" />
            </div>
            <Text
              content="Your password must be at least 8 characters and contain at least one capital letter, one symbol, and one number"
              size="sm" color="gray-800" />
            <div class="flex items-center">
              <Checkbox v-model:value="isChecked" id="accept-terms" name="accept-terms" for="accept-terms" class="text-md"
                :leading="true">
                <label for="accept-terms" class="block ml-2 text-sm text-gray-900">I accept the <b
                    @click.prevent="open = true" class="cursor-pointer">Terms and
                    Conditions</b> of using the DSCVRY platform</label>
              </Checkbox>
            </div>
            <div class="flex justify-start max-w-md py-6 mx-auto">
              <Button :disabled="!isValid" :content="button.label" variant="primary" :onClick="() => $emit('onSubmit')"
                :is-loading="isLoading" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex-none">
        <div class="mb-20">
          <div class="max-w-md mx-auto my-6 px-5 sm:px-2 flex space-x-2">
            <Text content="Already have an account?" color="gray-800" size="sm" />
            <Text @click="$emit('goTo', '/login')" content="Log In" color="primary-600"
              customClass="cursor-pointer self-start flex underline text-sm" />
          </div>
          <!-- <div class="fixed bottom-0 xs:relative sm:relative w-full py-3 border-t bg-gray-50 px-3 sm:px-0 ">
            <div class="flex justify-end max-w-md px-2 mx-auto">
              <Button :disabled="!isValid" :content="button.label" variant="primary" :onClick="() => $emit('onSubmit')"
                      :is-loading="isLoading"/>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="hidden h-full col-span-1 md:col-span-3 md:block">
      <Image :src="image.src" :alt="image.alt" width="full" height="screen" customClass="object-cover" />
    </div>
    <TermsAndConditionModal v-bind="terms" :open="open" @onClose="() => open = false" />
  </div>
</template>

<script>
/**
 - Combobox with text input to filter onh    basis of name property on default
 - give filter key to provide filter on a custom property
 **/
import Text from '../../../atoms/Text/Text.vue';
import Link from '../../../atoms/Link/Link.vue';
import Checkbox from '../../../molecules/Inputs/Checkbox/Checkbox.vue';
import Image from '../../../atoms/Image/Image.vue';
import Input from '../../../molecules/Inputs/Component/Component.vue';
import Button from '../../../atoms/Button/Button.vue';
import Icon from '../../../atoms/Icons/Icons.vue';
import TermsAndConditionModal from "../../../organisms/Modals/TermsAndConditionModal/TermsAndConditionModal";
import PasswordInput from "../../../molecules/Inputs/PasswordInput/PasswordInput.vue"

export default {
  emits: [
    'onChangeAgree'
  ],
  components: {
    TermsAndConditionModal,
    Text,
    Link,
    Checkbox,
    Image,
    Input,
    Button,
    Icon,
    PasswordInput
  },
  props: {
    image: {
      type: Object,
      default: () => {
      }
    },
    logo: {
      type: Object,
      default: () => {
      }
    },
    inputs: {
      type: Object,
      default: () => {
      }
    },
    button: {
      type: Object,
      default: () => {
      }
    },
    footerTitle: {
      type: String,
      default: ''
    },
    icons: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    terms: {
      type: Object,
      default: () => {
      }
    }
  },
  watch: {
    isChecked: function (val) {
      this.$emit('onChangeAgree', val)
      this.isValid = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(this.inputs.password.value) && /^\w+([+\.-]?\w+)*@\w+([+\.-]?\w+)*(\.\w{2,3})+$/.test(this.inputs.email.value) && this.inputs?.firstName?.value?.length > 0 && this.inputs?.lastName?.value?.length > 0 && this.isChecked;
    },
    inputs: {
      handler(val) {
        this.isValid = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(val.password.value) && /^\w+([+\.-]?\w+)*@\w+([+\.-]?\w+)*(\.\w{2,3})+$/.test(val.email.value) && val?.firstName?.value?.length > 0 && val?.lastName?.value?.length > 0 && this.isChecked;
      },
      deep: true
    }
  },
  data() {
    return {
      isChecked: false,
      isValid: false,
      open: false,
    }
  }
};
</script>
